import { apiHeaders, client, request } from "./_base";

const endpoint = "estimatedcostitem";
export const estimatedcostitems = client(endpoint, (item) => ({
  ...item,
}));

export const estimatedcostitemFull = async () => {
  const result = await request(`${endpoint}/ext/full`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const estimatedcostitemsAllSortedMappedforCombo = async () => {
  const result = await estimatedcostitems.all();
  return result
    .sort((a, b) => a.costdescription.localeCompare(b.costdescription))
    .map((item) => ({
      id: item.id,
      value: item.id,
      display: item.costdescription,
      type: item.type,
      group_id: item.estimatedcostitemgroups_id,
    }));
};
